import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { selectEuroDollar } from "../../redux/selector";
import { calculateEuroDollar } from "../../utils/calcul";
import TableContainer from "@mui/material/TableContainer";
import CustomTableCell from "../../shared/CustomTableCell";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

export default function TableData({ data }: { data: any }) {
  const euroDollar = useSelector(selectEuroDollar);
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            sx={{
              width: 30,
              height: 30,
            }}
            src={data["platform_logo"]}
          />
        }
        title={
          <Box display="flex" alignItems="center">
            <Typography
              fontSize={15}
              fontWeight={"bold"}
              mr={1}
              color="primary"
            >
              {data["platform_name"]}
            </Typography>
            <IconButton
              component="a"
              href={data["platform_url"]}
              target="_blank"
              rel="noopener noreferrer"
              size="small"
            >
              <InsertLinkIcon fontSize="small" />
            </IconButton>
          </Box>
        }
        action={
          <Typography fontWeight={"bold"} fontSize={15} color="success">
            {euroDollar.currency === "$"
              ? `$${parseFloat(data["platform_total_value"]).toFixed(2)}`
              : `${calculateEuroDollar(
                  parseFloat(data["platform_total_value"]),
                  euroDollar.currency,
                  euroDollar.valueEuroDollar
                ).toFixed(2)}€`}
          </Typography>
        }
      />
      <Divider variant="middle" />
      <CardContent>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="investment table">
            <TableHead>
              <TableRow>
                <CustomTableCell></CustomTableCell>
                <CustomTableCell>Pool</CustomTableCell>
                <CustomTableCell>Balance</CustomTableCell>
                <CustomTableCell align="right">
                  {euroDollar.currency === "$" ? "USD Value" : "EUR Value"}
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data["chains"][0]["investments"].map(
                (investment: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{investment.investment_name}</TableCell>
                    <TableCell>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        {/* Afficher les logos */}
                        <Stack direction="row">
                          {investment.token_list.map(
                            (token: any, tokenIndex: number) => (
                              <Avatar
                                key={tokenIndex}
                                src={token.logo}
                                alt={token.name}
                                sx={{
                                  width: 24,
                                  height: 24,
                                  marginLeft: tokenIndex > 0 ? "-8px" : "0px",
                                  border: "2px solid #222",
                                }}
                              />
                            )
                          )}
                        </Stack>

                        <Typography>
                          {investment.token_list.length > 1
                            ? `LP-${investment.token_list
                                .map((t: any) => t.symbol)
                                .join("-")}`
                            : investment.token_list[0]?.symbol}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {investment.token_list.map((token: any, i: number) => (
                        <Typography key={i}>{`${token.token_amount.toFixed(
                          4
                        )} ${token.symbol}`}</Typography>
                      ))}
                    </TableCell>
                    <TableCell align="right">
                      {euroDollar.currency === "$"
                        ? `$${investment.total_value.toFixed(2)}`
                        : `${calculateEuroDollar(
                            parseFloat(investment.total_value),
                            euroDollar.currency,
                            euroDollar.valueEuroDollar
                          ).toFixed(2)}€`}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
