// src/hook/useManageDataTransaction.ts

import { useState, useEffect } from "react";
import { Transaction } from "../types/transaction";
import { ARRAY_BLOCKCHAIN_BASED_ETHEREUM } from "../constantes/value";
import { getUniqueBlockchains } from "../features/AssetListing/helper";

// Fonction pour comparer deux dates sans tenir compte des heures, minutes, secondes
const areDatesEqual = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const useFilterTransactions = (
  transactionData: Transaction[] | undefined,
  setDataFilterTransaction: React.Dispatch<
    React.SetStateAction<Transaction[] | undefined>
  >
) => {
  const [filterItem, setFilterItem] = useState<string>("ALL");
  const [isFilterByBlockchain, setIsFilterByBlockchain] =
    useState<boolean>(false);
  const [blockChainSelect, setBlockChainSelect] = useState<
    { name: string; value: string }[]
  >([]);
  const [filterByBlockchainValue, setFilterByBlockchainValue] =
    useState<string>("");

  // Nouveaux états pour le filtrage par date
  const [startDate, setStartDate] = useState<Date | null>(null);

  useEffect(() => {
    if (transactionData) {
      let filteredData = transactionData;

      // Filtrage par type de transaction
      if (filterItem !== "ALL") {
        if (filterItem === "SWAP") {
          // Compter les occurrences de chaque tx_hash
          const hashCount = transactionData.reduce((acc, el: Transaction) => {
            if (ARRAY_BLOCKCHAIN_BASED_ETHEREUM.includes(el.blockchain!)) {
              acc[el.tx_hash!] = (acc[el.tx_hash!] || 0) + 1;
            }
            return acc;
          }, {} as Record<string, number>);

          // Filtrer uniquement les tx_hash qui apparaissent plus d'une fois
          const repeatedHashes = new Set(
            Object.keys(hashCount).filter((hash) => hashCount[hash] > 1)
          );

          // Retourner toutes les transactions qui ont ces hashes
          filteredData = transactionData.filter((el: Transaction) =>
            repeatedHashes.has(el.tx_hash!)
          );
        } else if (isFilterByBlockchain && filterByBlockchainValue !== "") {
          filteredData = transactionData.filter((el: Transaction) =>
            filterByBlockchainValue === "OTHERS"
              ? el.transaction_type === filterItem && el.blockchain === null
              : el.transaction_type === filterItem &&
                el.blockchain === filterByBlockchainValue
          );
        } else {
          filteredData = transactionData.filter(
            (el: Transaction) => el.transaction_type === filterItem
          );
        }
      }

      // Filtrage par date
      if (startDate) {
        filteredData = filteredData.filter((el: Transaction) => {
          const elDate = new Date(el.date * 1000);
          // Comparer uniquement l'année, le mois et le jour
          return areDatesEqual(elDate, startDate);
        });
      }
      setDataFilterTransaction(filteredData);
    }
  }, [
    filterItem,
    transactionData,
    filterByBlockchainValue,
    isFilterByBlockchain,
    startDate,
    setDataFilterTransaction,
  ]);

  useEffect(() => {
    if (transactionData) {
      setBlockChainSelect(getUniqueBlockchains(transactionData));
    }
  }, [isFilterByBlockchain, transactionData]);

  useEffect(() => {
    if (transactionData) {
      let filteredData = transactionData;
      if (filterByBlockchainValue !== "") {
        filteredData =
          filterByBlockchainValue !== "OTHERS"
            ? transactionData.filter(
                (el: Transaction) => el.blockchain === filterByBlockchainValue
              )
            : transactionData.filter(
                (el: Transaction) => el.blockchain === null
              );
        setDataFilterTransaction(filteredData);
      } else {
        setDataFilterTransaction(transactionData);
      }
    }
  }, [filterByBlockchainValue, transactionData, setDataFilterTransaction]);

  return {
    filterItem,
    isFilterByBlockchain,
    blockChainSelect,
    filterByBlockchainValue,
    setFilterItem,
    setIsFilterByBlockchain,
    setFilterByBlockchainValue,
    startDate,
    setStartDate,
  };
};

// Hook pour la recherche des transactions
export const useSearchTransactions = (
  transactionData: Transaction[] | undefined,
  setDataFilterTransaction: React.Dispatch<
    React.SetStateAction<Transaction[] | undefined>
  >
) => {
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isFiltering, setIsFiltering] = useState<boolean>(true);

  useEffect(() => {
    let filteredData = transactionData || [];

    if (isSearch && searchValue.trim() !== "") {
      const searchTerm = searchValue.trim().toUpperCase();
      filteredData = filteredData.filter((el) => {
        const txHash = el.tx_hash?.toUpperCase() || "";
        const symbol = el.asset_symbol?.toUpperCase() || "";
        const walletAdresse = el.wallet_adress?.toUpperCase() || ""
        return txHash.includes(searchTerm) || symbol.includes(searchTerm) || walletAdresse.includes(searchTerm);
      });
    }
    setDataFilterTransaction(filteredData);
    setIsFiltering(false);
  }, [searchValue, transactionData, isSearch, setDataFilterTransaction]);

  return {
    isSearch,
    searchValue,
    setSearchValue,
    setIsSearch,
    isFiltering,
  };
};
