import { ApiKeys, Wallet } from "../types/keys";
import { RouteAxios } from "./axios";

// ----------------------API KEYS-------------------------//
export const getApiKeys = async (userId: number) => {
  return (await RouteAxios.get<ApiKeys[]>("/apikeys/?user__id=" + userId)).data;
};
export const AddApiKey = async (
  userId: number,
  platform: string,
  data: {
    public_key: string;
    private_key: string;
  }
) => {
  await RouteAxios.post("/apikeys/", {
    ...data,
    user: userId,
    platform: platform,
  });
};

export const DeleteApiKey = (id: number) => {
  return RouteAxios.delete(`/apikeys/${id}/`);
};

export const UpdateApiKey = (
  id: number,
  data: {
    public_key: string;
    private_key: string;
  }
) => {
  return RouteAxios.patch(`/apikeys/${id}/`, data);
};

//----------------------- WALLET -----------------------//
export const getWallets = async (userId: number) => {
  return (await RouteAxios.get<Wallet[]>("/wallets/?user__id=" + userId)).data;
};

export const addWallet = ({
  user,
  address,
  blockchain,
  storage_type,
  label,
}: {
  user: number;
  address: string;
  blockchain: string;
  storage_type: string;
  label: string;
}) => {
  return RouteAxios.post("/wallets/", {
    user: user,
    address: address,
    blockchain: blockchain,
    storage_type: storage_type,
    label: label,
  });
};

export const deleteWallet = (id: number) => {
  return RouteAxios.delete(`/wallets/${id}/`);
};

export const updateWallet = (
  id: number,
  data: {
    address: string;
    storage_type: string;
    label: string;
  }
) => {
  return RouteAxios.patch(`/wallets/${id}/`, data);
};
