// User
export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_CONNECTED = "SET_CONNECTED";
export const USER_LOGOUT = "USER_LOGOUT";
export const SETTINGS = "SETTINGS";
export const SETUSERMANAGED = "SETUSERMANAGED";
export const SET_INVALIDATE_DATA_SEETING = "SET_INVALIDATE_DATA_SEETING";
export const SET_MANAGER = "SET_MANAGER";
export const SET_ACCOUNT_MANAGER = "SET_ACCOUNT_MANAGER";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_TOGGLE_EURO_DOLLAR = "SET_TOGGLE_EURO_DOLLAR";
export const SET_LIVE_PRICE = "SET_LIVE_PRICE";
export const SET_ASSET_DATA = "SET_ASSET_DATA";
export const SET_TIME_MACHINE = "SET_TIME_MACHINE";
export const SET_DISPLAY_PORTFOLIO = 'SET_DISPLAY_PORTFOLIO'
export const SET_ASSET_REPORT = "SET_ASSET_REPORT";
export const ADD_ASSET_TO_USER = "ADD_ASSET_TO_USER";
export const REMOVE_ASSET_FROM_USER = "REMOVE_ASSET_FROM_USER";