export const text = {
  DEFAULT_TITLE: {
    fr: "POINT CRYPTO",
    en: "PORTFOLIO FOCUS",
  },
  DEFAULT_SUBTITLE: {
    fr: "Bilan Portefeuille",
    en: "Balance Sheet",
  },
  BALANCE_SHEET_DATE: {
    fr: "Date du relevé",
    en: "Date",
  },
  FIRST_PURCHASE: {
    fr: "1er versement",
    en: "1st purchase",
  },
  APY: {
    fr: "Performance annualisée",
    en: "Annual Percentage Yield",
  },
  CURRENT_MONTH: {
    fr: "Mois en cours (exprimé en français, Janvier, Février, etc.)",
    en: "Current month (expressed in English, January, February, etc.)",
  },
  CURRENT_YEAR: {
    fr: "Année en cours au format numérique sur 4 chiffres (e.g. 2024).",
    en: "Current year in numeric format (e.g. 2024).",
  },
  OVERVIEW: {
    fr: "Synthèse",
    en: "Overview",
  },
  PNL: {
    fr: "Rendement",
    en: "PnL",
  },
  NET_WORTH: {
    fr: "Valorisation de la portfeuille",
    en: "Net Worth Portfolio",
  },
  NET_WORTH_STAKING: {
    fr: "Valorisation du staking",
    en: "Net Worth Staking",
  },
  TOTAL_NET_WORTH: {
    fr: "Valorisation total",
    en: "Total Net Worth",
  },
  INVESTMENT: {
    fr: "Investissement",
    en: "Investment",
  },
  FIAT_BALANCE: {
    fr: "Euros",
    en: "Fiat Balance",
  },
  STABLE_COIN_BALANCE: {
    fr: "Stable coins",
    en: "Stable coins",
  },
  PORTFOLIO_BALANCE: {
    fr: "Total portefeuille",
    en: "Portfolio Balance",
  },
  ASSET_ALLOCATION: {
    fr: "Allocation",
    en: "Asset Allocation",
  },
  ASSET: {
    fr: "Actif",
    en: "Asset",
  },
  ASSET_LIST: {
    fr: "Liste des actifs",
    en: "Asset List",
  },
  OWNED_ASSETS: {
    fr: "Détenus",
    en: "Owned",
  },
  PNL_VALUE: {
    fr: "Rendement (valeur)",
    en: "PnL Value",
  },
  PNL_PERCENT: {
    fr: "Rendement (pourcent)",
    en: "PnL %",
  },
  AVG_PURCHASE_PRICE: {
    fr: "Prix Moy. Achat",
    en: "Avg Purchase Price",
  },
  MANAGER_COMMENT: {
    fr: "Commentaire de gestion",
    en: "Manager comment",
  },
  DAY: {
    fr: "Jours",
    en: "Days",
  },
  NO_DATE_AVAILABLE: {
    fr: "Pas de date valide",
    en: "No date available",
  },
  SITUATION: {
    fr: "Situation au",
    en: "Situation on",
  },
  BETWEEN: {
    fr: "entre",
    en: "between",
  },
  NO_TRANSACTIONS: {
    fr: "Pas de transactions",
    en: "No transactions",
  },
  NO_DATA: {
    fr: "Pas de données",
    en: "No data",
  },
};
