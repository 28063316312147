import {
  SET_ASSET_REPORT,
  ADD_ASSET_TO_USER,
  REMOVE_ASSET_FROM_USER,
} from "../../constantes/redux";
import { TAssetReportState, TAssetReportAction } from "../../types/asset";

const initialState: TAssetReportState = {};

/**
 * Reducer pour gérer l'ajout, la suppression et la mise à jour des assets par utilisateur
 */
export default function assetReportReducer(
  state = initialState,
  action: TAssetReportAction
): TAssetReportState {
  switch (action.type) {
    case SET_ASSET_REPORT:
      return action.payload; // Remplace tout l'état

    case ADD_ASSET_TO_USER: {
      const payload = action.payload as Record<string, string[]>;

      return {
        ...state,
        ...Object.keys(payload).reduce((acc, name) => {
          acc[name] = state[name]
            ? [...new Set([...state[name], ...payload[name]])]
            : payload[name];
          return acc;
        }, {} as TAssetReportState),
      };
    }

    case REMOVE_ASSET_FROM_USER: {
      const payload = action.payload as Record<string, string[]>;

      const newState = { ...state };

      Object.keys(payload).forEach((name) => {
        if (!newState[name]) return; // Si l'utilisateur n'existe pas, on ne fait rien

        newState[name] = newState[name].filter(
          (asset) => !payload[name].includes(asset)
        );

        if (newState[name].length === 0) {
          delete newState[name]; // Supprimer l'utilisateur si plus d'assets
        }
      });

      return newState;
    }

    default:
      return state;
  }
}
