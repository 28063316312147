import Box from "@mui/material/Box";
import DetailInformation from "./Details";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import { NO_DATA } from "../../../constantes/app";
import { allStateReduxType } from "../../../types/redux";
import { SnapshotAssetList } from "../../../types/snapshot";
import { selectAssetReport } from "../../../redux/selector";
import { getTextByLanguage } from "../../../utils/function";

export default function Information({
  data,
  colums = "all",
  isReportLastDate = false,
  isReport,
  isAllowReportChoose,
  filterData,
  language = "fr", // Default value set here
  isTranslation = false, // Default value set here
}: {
  data: SnapshotAssetList[];
  colums: string[] | "all";
  isReportLastDate: boolean;
  isReport?: boolean;
  isAllowReportChoose?: boolean;
  filterData: string;
  language?: "fr" | "en"; // Optional parameter
  isTranslation?: boolean; // Optional parameter
}) {
  const userName = useParams<{ userName: string }>().userName;

  const [filteredData, setFilteredData] = useState<SnapshotAssetList[]>(data);

  const userAssetReport = useSelector((state: allStateReduxType) =>
    selectAssetReport(state, userName!)
  );

  useEffect(() => {
    if (isReport) {
      const filtered = data.filter((item) =>
        userAssetReport.includes(item.symbol)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [data, isReport, userAssetReport]);

  return (
    <>
      {filteredData.length > 0 ? (
        filteredData.map((row) => (
          <DetailInformation
            key={row.symbol}
            row={row}
            colums={colums}
            isReportLastDate={isReportLastDate}
            isAllowReportChoose={isAllowReportChoose}
            filterData={filterData}
          />
        ))
      ) : (
        <>
          <TableCell colSpan={10}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
                marginTop: 2,
                fontSize: "1.5rem",
              }}
            >
              {isTranslation ? getTextByLanguage("NO_DATA", language) : NO_DATA}
            </Box>
          </TableCell>
        </>
      )}
    </>
  );
}
