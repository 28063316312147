import Highcharts from "highcharts";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Asset } from "../../types/asset";
import Loading from "../../shared/Loading";
import color from "../../constantes/color";
import Divider from "@mui/material/Divider";
import { NO_DATA } from "../../constantes/app";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { useEffect, useMemo, useState } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import HighchartsReact from "highcharts-react-official";
import Accessibility from "highcharts/modules/accessibility";

// Activer le module d'accessibilité
Accessibility(Highcharts);

type formatData = {
  name: string;
  y: number;
};

export default function AssetByGroup({
  asset,
  isStaking
}: {
  asset: UseQueryResult<Asset[], Error>;
  isStaking?: boolean; // Pour activer le rapport sur les allocations
}) {
  const [finalData, setFinalData] = useState<formatData[]>([]);
  const [totalData, setTotalData] = useState<number>(0);

  useEffect(() => {
    if (asset.data && asset.data.length) {
      const groupedData: { [key: string]: number } = {};

      // Regrouper les données par group et additionner les valeurs
      asset.data.forEach((el) => {
        const group = el.group;
        groupedData[group] =
          (groupedData[group] || 0) + parseFloat(el.net_worth);
      });

      // Formatter et trier les données
      const formattedData = Object.entries(groupedData)
        .map(([name, count]) => ({ name, y: count }))
        .sort((a, b) => b.y - a.y);

      const total = formattedData.reduce((sum, item) => sum + item.y, 0);

      // Mettre à jour les états
      setFinalData(formattedData);
      setTotalData(total);
    }
  }, [asset.data]);

  const options = useMemo(
    () => ({
      credits: {
        enabled: false,
      },
      chart: {
        type: "pie",
        height: 270,
        animation: false, // Désactiver les animations
      },
      title: {
        text: "",
      },
      tooltip: {
        // @ts-ignore
        formatter: function () {
          // @ts-ignore
          return `<b>${this.point.name}</b><br/> ~ ${Highcharts.numberFormat(
            // @ts-ignore
            this.point.percentage,
            1
          )} %`;
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
          colors: ["#01BAF2", "#71BF45", "#FAA74B", "#B37CD2"],
          showInLegend: true,
        },
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
        itemMarginBottom: 10,
        symbolRadius: 0,
        symbolHeight: 8,
        symbolWidth: 8,
        // @ts-ignore
        labelFormatter: function () {
          // @ts-ignore
          return `${this.name}: ${Highcharts.numberFormat(
            // @ts-ignore
            (this.y / totalData) * 100,
            1
          )} %`;
        },
      },
      series: [
        {
          innerSize: "75%",
          data: finalData,
        },
      ],
    }),
    [finalData, totalData]
  );

  return (
    <Card
      sx={{
        borderRadius: "1rem",
      }}
    >
      <CardHeader
        title="Asset By Group"
        sx={{
          textAlign: "center",
        }}
      />
      <Divider variant="middle" />
      <CardContent sx={{ mt: -2, height:isStaking ? "29.75rem" : "22.75rem" }}>
        {asset.isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Loading size={15} color="inherit" />
          </Box>
        ) : asset.isError ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: color.red,
              fontWeight: "bold",
              fontSize: "1.2rem",
              textAlign: "center",
              height: "100%",
            }}
          >
            {asset.error.message}
          </Box>
        ) : (
          <>
            {asset.data && asset.data.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%", // S'assure que le Box prend toute la largeur
                  height: "100%", // S'assure que le Box prend toute la hauteur
                }}
              >
                <HighchartsReact highcharts={Highcharts} options={options} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  height: "100%",
                }}
              >
                {NO_DATA}
              </Box>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
