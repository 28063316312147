import {
  SET_ASSET_REPORT,
  ADD_ASSET_TO_USER,
  REMOVE_ASSET_FROM_USER,
} from "../../constantes/redux";
import { TAssetReportState } from "../../types/asset";

/**
 * Action pour remplacer complètement l'état
 */
export const setAssetReport = (data: TAssetReportState) => ({
  type: SET_ASSET_REPORT,
  payload: data,
});

/**
 * Action pour ajouter ou mettre à jour les assets d'un ou plusieurs utilisateurs
 * Exemple: addAssetToUser("sergio", ["ETH", "BTC"]) => payload = { sergio: ["ETH", "BTC"] }
 */
export const addAssetToUser = (name: string, assets: string[]) => ({
  type: ADD_ASSET_TO_USER,
  payload: { [name]: assets }, // <-- On renvoie un Record<string, string[]>
});

/**
 * Action pour supprimer un ou plusieurs assets chez un ou plusieurs utilisateurs
 * Exemple: removeAssetFromUser("sergio", ["BTC"]) => payload = { sergio: ["BTC"] }
 */
export const removeAssetFromUser = (name: string, assets: string[]) => ({
  type: REMOVE_ASSET_FROM_USER,
  payload: { [name]: assets }, // <-- On renvoie un Record<string, string[]>
});
