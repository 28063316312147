import {
  FEES,
  ASSET,
  PRICE,
  PNL_VALUE,
  NET_WORTH,
  INVESTMENT,
  OWNED_ASSET,
  PNL_PERCENTAGE,
  AVERAGE_PURCHASE_PRICE,
} from "../../../constantes/app";
import {
  selectLivePrice,
  selectEuroDollar,
  selectTimeMachine,
  selectDisplayPortfolio,
} from "../../../redux/selector";
import {
  addAssetToUser,
  removeAssetFromUser,
} from "../../../redux/action/asset_report";
import Box from "@mui/material/Box";
import { getColor } from "../helper";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../shared/Loading";
import color from "../../../constantes/color";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import { deepPurple } from "@mui/material/colors";
import PriceHelper from "../../../shared/PriceHelper";
import { useDispatch, useSelector } from "react-redux";
import { IToggleEuroDollar } from "../../../types/others";
import { SnapshotAssetList } from "../../../types/snapshot";
import { calculateEuroDollar } from "../../../utils/calcul";
import { useGetLogoCrypto } from "../../../hook/useGetLogoCrypto";

const formatCurrency = (value: number, euroDollar: IToggleEuroDollar) => {
  const result = calculateEuroDollar(
    value,
    euroDollar.currency,
    euroDollar.valueEuroDollar
  );
  return isNaN(result) || result === Infinity || result === -Infinity ? (
    <PriceHelper />
  ) : (
    result.toFixed(2)
  );
};

export default function DetailInformation({
  row,
  colums = "all",
  isReportLastDate = false,
  isAllowReportChoose,
  filterData,
}: {
  row: SnapshotAssetList;
  colums: string[] | "all";
  isReportLastDate: boolean;
  isAllowReportChoose?: boolean;
  filterData: string;
}) {
  const userName = useParams<{ userName: string }>().userName;

  // Redux state
  const dispatch = useDispatch();
  const euroDollar = useSelector(selectEuroDollar);
  const livePrice = useSelector(selectLivePrice);
  const displayPortFolio = useSelector(selectDisplayPortfolio);
  const timeMachine = useSelector(selectTimeMachine);
  const logo = useGetLogoCrypto(row.symbol);

  const getInitialCheckedState = () => {
    if (!isAllowReportChoose) return true;
    if (displayPortFolio.isActive || timeMachine.isActive)
      return !!row.price && row.price > 0;
    return !!livePrice[row.symbol];
  };
  const [isChecked, setIsChecked] = useState(getInitialCheckedState);

  useEffect(() => {
    if (!isAllowReportChoose) return;
    const action = isChecked ? addAssetToUser : removeAssetFromUser;
    dispatch(action(userName!, [row.symbol]));
  }, [isChecked, isAllowReportChoose, row.symbol, dispatch, userName]);

  const handleCheckboxChange = () => setIsChecked((prev) => !prev);

  if (isAllowReportChoose && filterData === "Essential") {
    if (
      !displayPortFolio.isActive &&
      !timeMachine.isActive &&
      !livePrice[row.symbol]
    ) {
      return null;
    }
  }

  return (
    <TableRow key={row.symbol}>
      {isAllowReportChoose && userName && (
        <TableCell align="center">
          <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
        </TableCell>
      )}
      <TableCell align="right">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {logo.isLoading ? (
            <Loading color="inherit" />
          ) : (
            <>
              {logo.data?.logo ? (
                <Avatar
                  src={logo.data?.logo}
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 50,
                    borderWidth: 2,
                    marginRight: 1,
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    bgcolor: deepPurple[500],
                    width: 30,
                    height: 30,
                    borderRadius: 50,
                    borderWidth: 2,
                    marginRight: 1,
                    fontWeight: "bold",
                  }}
                >
                  {row.symbol.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </>
          )}
        </Box>
      </TableCell>
      {(colums === "all" || colums.includes(ASSET)) && (
        <TableCell align="left" sx={{ fontWeight: "bold" }}>
          {row.symbol}
        </TableCell>
      )}
      {(colums === "all" || colums.includes(OWNED_ASSET)) && (
        <TableCell align="center" sx={{ fontWeight: "bold" }}>
          {row.ownedAssets.toFixed(8)}
        </TableCell>
      )}
      {(colums === "all" || colums.includes(PRICE)) && (
        <TableCell
          align="center"
          sx={{ color: color.green, fontWeight: "bold" }}
        >
          {displayPortFolio.isActive || timeMachine.isActive ? (
            formatCurrency(row.price!, euroDollar)
          ) : livePrice[row.symbol] ? (
            formatCurrency(livePrice[row.symbol], euroDollar)
          ) : (
            <PriceHelper />
          )}
        </TableCell>
      )}
      {(colums === "all" || colums.includes(NET_WORTH)) && (
        <TableCell
          align="center"
          sx={{ color: getColor(row.netWorth), fontWeight: "bold" }}
        >
          {displayPortFolio.isActive ||
          timeMachine.isActive ||
          isReportLastDate ? (
            formatCurrency(row.netWorth, euroDollar)
          ) : livePrice[row.symbol] ? (
            formatCurrency(livePrice[row.symbol] * row.ownedAssets, euroDollar)
          ) : (
            <PriceHelper />
          )}
        </TableCell>
      )}
      {(colums === "all" || colums.includes(INVESTMENT)) && (
        <TableCell align="center" sx={{ fontWeight: "bold" }}>
          {row.avgPurchasePrice > 0 ? (
            formatCurrency(row.investissement, euroDollar)
          ) : (
            <PriceHelper />
          )}
        </TableCell>
      )}
      {(colums === "all" || colums.includes(PNL_VALUE)) && (
        <TableCell
          align="center"
          sx={{ color: getColor(row.pnlValue), fontWeight: "bold" }}
        >
          {livePrice[row.symbol] && row.avgPurchasePrice > 0 ? (
            formatCurrency(row.pnlValue, euroDollar)
          ) : (
            <PriceHelper />
          )}
        </TableCell>
      )}
      {(colums === "all" || colums.includes(PNL_PERCENTAGE)) && (
        <TableCell
          align="center"
          sx={{ color: getColor(row.pnlRate), fontWeight: "bold" }}
        >
          {livePrice[row.symbol] && row.avgPurchasePrice > 0 ? (
            isNaN(row.pnlRate) ? (
              <PriceHelper />
            ) : (
              row.pnlRate.toFixed(2)
            )
          ) : (
            <PriceHelper />
          )}
        </TableCell>
      )}
      {(colums === "all" || colums.includes(FEES)) && (
        <TableCell align="center" sx={{ fontWeight: "bold" }}>
          {formatCurrency(row.fees, euroDollar)}
        </TableCell>
      )}
      {(colums === "all" || colums.includes(AVERAGE_PURCHASE_PRICE)) && (
        <TableCell align="center" sx={{ fontWeight: "bold" }}>
          {row.avgPurchasePrice > 0 ? (
            formatCurrency(row.avgPurchasePrice, euroDollar)
          ) : (
            <PriceHelper />
          )}
        </TableCell>
      )}
    </TableRow>
  );
}
