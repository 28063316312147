import {
  MY_PROFILE,
  MANAGE_CLIENT_OR_TEAM,
  DISPLAY_PORTFOLIO_DATE,
} from "../constantes/app";
import {
  SET_CURRENCY,
  SET_TIME_MACHINE,
  SET_DISPLAY_PORTFOLIO,
} from "../constantes/redux";
import {
  DASHBOARD_ROUTE,
  MY_PROFILE_ROUTE,
  PRICE_CALCULATOR_ROUTE,
  MANAGE_CLIENT_AND_TEAM_ROUTE,
} from "../constantes/routes";
import React from "react";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { format } from "date-fns";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import color from "../constantes/color";
import useLogout from "../hook/useLogout";
import Avatar from "@mui/material/Avatar";
import Switch from "@mui/material/Switch";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { selectUser } from "../redux/selector";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import { selectEuroDollar } from "../redux/selector";
import { useSelector, useDispatch } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { selectDisplayPortfolio } from "../redux/selector";
import { useLocation, useNavigate } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useGetUserConnectedOrSelected } from "../hook/useGetUserConnectedOrSelected";

const drawerWidth = 240;

const yesterday = dayjs().subtract(1, "day");

export default function AppBar_({
  isClosing,
  userName,
}: {
  isClosing: boolean;
  userName: string | undefined;
}) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isDisplayPortfolio, setIsDisplayPortfolio] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  // const [openGenerateMonthlyReport, setOpenGenerateMonthlyReport] =
  //   React.useState(false);

  const dispatch = useDispatch();
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const handleLogout = useLogout();

  const user = useSelector(selectUser).data;
  const euroDollar = useSelector(selectEuroDollar);
  const displayPortFolio = useSelector(selectDisplayPortfolio);
  const userSelected = useGetUserConnectedOrSelected(userName);

  const MySwal = withReactContent(Swal);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleOpensetDisplayPortfolio = () => {
    setIsDisplayPortfolio(true);
    dispatch({
      type: SET_TIME_MACHINE,
      payload: { isActive: false, startDate: null, endDate: null },
    });
  };

  const handleCloseDisplayPortfolio = () => {
    setIsDisplayPortfolio(false);
    dispatch({
      type: SET_DISPLAY_PORTFOLIO,
      payload: { isActive: false, date: null },
    });
  };

  const handleChangeSwitch = (event: any) => {
    if (event.target.checked) {
      dispatch({ type: SET_CURRENCY, payload: { currency: "€" } });
    } else {
      dispatch({ type: SET_CURRENCY, payload: { currency: "$" } });
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: `${color.white}`,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon color="action" />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {userSelected && (
              <Typography
                component="div"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: `${color.black}`,
                  fontWeight: "bold",
                  marginRight: 2,
                }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={userSelected?.profile_image_url}
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 50,
                    border: `1px solid ${color.green} `,
                    borderWidth: 2,
                    marginRight: 1,
                  }}
                />{" "}
                {userSelected?.username}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
            }}
          >
            {isDisplayPortfolio || displayPortFolio.isActive
              ? (path.includes(DASHBOARD_ROUTE) ||
                  path.includes(PRICE_CALCULATOR_ROUTE)) &&
                (userName || !user?.is_manager) && (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Choose or type a date"
                        shouldDisableDate={(date) =>
                          dayjs(date).isAfter(yesterday)
                        }
                        value={
                          displayPortFolio.date
                            ? new Date(displayPortFolio.date)
                            : null
                        }
                        onChange={(newValue) => {
                          // Vérifiez si newValue n'est pas null ou undefined
                          if (newValue) {
                            try {
                              // Assurez-vous que la date est valide avant de la formater
                              const formattedDate = format(
                                newValue,
                                "yyyy-MM-dd"
                              );

                              // Dispatch l'action avec la date formatée
                              dispatch({
                                type: SET_DISPLAY_PORTFOLIO,
                                payload: {
                                  isActive: true,
                                  date: formattedDate, // Date formatée
                                },
                              });
                            } catch (error) {
                              console.error("Invalid date:", error);
                            }
                          } else {
                            console.warn("Date is invalid or null");
                          }
                        }}
                        slotProps={{
                          textField: {
                            sx: {
                              "& .MuiInputBase-root": {
                                height: "43px",
                                color: color.black,
                                fontSize: 14,
                                display: "flex",
                                alignItems: "center", // Centre le texte verticalement
                              },
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                    <Tooltip title="Reset Time Machine">
                      <IconButton onClick={handleCloseDisplayPortfolio}>
                        <HighlightOffIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )
              : (path.includes(DASHBOARD_ROUTE) ||
                  path.includes(PRICE_CALCULATOR_ROUTE)) &&
                (userName || !user?.is_manager) && (
                  <Stack
                    direction="row"
                    spacing={0}
                    alignItems="center"
                    marginRight={2}
                  >
                    <Typography
                      sx={{
                        color: `${color.black}`,
                        fontWeight: "bold",
                      }}
                    >
                      {DISPLAY_PORTFOLIO_DATE}
                    </Typography>
                    <Switch onChange={handleOpensetDisplayPortfolio} />
                  </Stack>
                )}
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            {/* {userName && (
              <Button
                variant="contained"
                sx={{
                  marginRight: 2,
                }}
                onClick={() => {
                  setOpenGenerateMonthlyReport(true);
                }}
              >
                Generate Report
              </Button>
            )} */}
            <Stack
              direction="row"
              spacing={0}
              alignItems="center"
              marginRight={2}
            >
              <Typography
                sx={{
                  color: `${color.black}`,
                  fontWeight: "bold",
                }}
              >
                $
              </Typography>
              <Switch
                defaultChecked={euroDollar.currency === "€"}
                onChange={handleChangeSwitch}
              />
              <Typography
                sx={{
                  color: `${color.black}`,
                  fontWeight: "bold",
                }}
              >
                €
              </Typography>
            </Stack>

            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: `${color.black}`,
                fontWeight: "bold",
                marginRight: 2,
              }}
            >
              {user?.is_manager || user?.is_account_manager
                ? `${user?.first_name}`
                : `${user?.username}`}
            </Typography>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src={user?.profile_image_url} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                key={"my-profile"}
                onClick={() => {
                  handleCloseUserMenu();
                  navigate(MY_PROFILE_ROUTE);
                }}
              >
                <PersonIcon />
                <Typography textAlign="center">{MY_PROFILE}</Typography>
              </MenuItem>
              {user && (user.is_manager || user.is_account_manager) && (
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate(MANAGE_CLIENT_AND_TEAM_ROUTE);
                  }}
                >
                  <PeopleAltIcon />{" "}
                  <Typography textAlign="center">
                    {MANAGE_CLIENT_OR_TEAM}
                  </Typography>
                </MenuItem>
              )}

              <MenuItem
                key={"logout"}
                onClick={() => {
                  handleCloseUserMenu();
                  MySwal.fire({
                    title: "",
                    text: `Are you sure you want to logout?`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: `${color.bluePrimary}`,
                    cancelButtonColor: `${color.red}`,
                    confirmButtonText: "Yes, logout!",
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleLogout();
                    }
                  });
                }}
              >
                <PowerSettingsNewIcon />
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
