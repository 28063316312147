import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid2";
import { motion } from "framer-motion";
import Loading from "../shared/Loading";
import color from "../constantes/color";
import { ERROR } from "../constantes/app";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AggregatedDataSnapshot } from "../types/index";
import { useFilterSnapshots } from "../hook/useFilterSnapshots";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useInitializeData } from "../hook/useInitializeDataDashboard";
import { selectDisplayPortfolio, selectUser } from "../redux/selector";
import { useGetUserConnectedOrSelected } from "../hook/useGetUserConnectedOrSelected";

// Components
import DeFi from "../features/DeFi";
import OverView from "../features/Overview";
import { useGetDeFi } from "../hook/useGetDeFi";
import AssetByGroup from "../features/AssetByGroup";
import AssetListing from "../features/AssetListing";
import CumulativePnl from "../features/CumulativePnl";
import AssetAllocation from "../features/AssetAllocation";
import AssetDistrubition from "../features/AssetDistrubition";
import { useManageUserSelectedID } from "../hook/useManageUserSelectedID";

// Composant du bouton de retour en haut
const ScrollToTopButton = ({ onClick }: { onClick: () => void }) => (
  <motion.div
    animate={{
      y: [0, -8, 0],
    }}
    transition={{
      duration: 1.5,
      repeat: Infinity,
      ease: "easeInOut",
    }}
    style={{
      position: "fixed",
      bottom: 16,
      right: 16,
      zIndex: 1000,
    }}
  >
    <Fab
      color="primary"
      aria-label="Retour en haut"
      onClick={onClick}
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        backgroundColor: color.blueSecondary,
        "&:hover": {
          backgroundColor: color.bluePrimary,
        },
      }}
    >
      <KeyboardArrowUpIcon />
    </Fab>
  </motion.div>
);

export default function Dashboard() {
  const userName = useParams<{ userName: string }>().userName;
  const dispatch = useDispatch();

  // date of the day
  const today = new Date().toISOString().split("T")[0];

  // state
  const [title, setTitle] = useState<string>("overview");
  const [selectData, setSelectData] = useState<
    AggregatedDataSnapshot | undefined
  >();
  const [isVisible, setIsVisible] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isStaking, setIsStaking] = useState(false);

  // Récupère tous les snapshots nécessaires et les valeurs mémorisées
  const { users, asset, snapshot, portfolioSnapshot } = useInitializeData(
    dispatch,
    userName
  );

  const userId = useManageUserSelectedID(userName);
  const deFi = useGetDeFi(userId!);

  // selectors
  const displayPortFolio = useSelector(selectDisplayPortfolio);
  const userSelected = useGetUserConnectedOrSelected(userName);
  const userConnected = useSelector(selectUser);

  // Filtrage des snapshots
  const { snapshotToday, portfolioSnapshotToday } = useFilterSnapshots({
    snapshot,
    portfolioSnapshot,
    userSelected,
    displayPortFolio,
    today,
  });

  // Gestion de l'affichage du bouton de scroll
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 300);
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Affiche un écran de chargement ou d'erreur
  if (users.isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading size={20} />
      </Box>
    );
  }

  if (users.isError) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          color: color.red,
          fontWeight: "bold",
          fontSize: 20,
        }}
      >
        {ERROR} : {users.error?.message}
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        {userName || !userConnected?.data?.is_manager ? (
          <>
            <Grid size={{ xs: 12, sm: 12, md: 3 }}>
              <OverView
                snapshotToday={snapshotToday}
                snapshot={snapshot}
                selectData={selectData}
                setSelectData={setSelectData}
                asset={asset}
                title={title}
                setTitle={setTitle}
                dataPortfolio={portfolioSnapshotToday}
                portfolioSnapshot={portfolioSnapshot}
                isStaking={isStaking}
                setIsStaking={setIsStaking}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <AssetAllocation
                snapshot={snapshot}
                snapshotToday={snapshotToday}
                isStaking={isStaking}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <AssetDistrubition asset={asset} isStaking={isStaking} />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <AssetByGroup asset={asset} isStaking={isStaking} />
            </Grid>
          </>
        ) : (
          <>
            <Grid size={{ xs: 12, sm: 12, md: 4 }}>
              <AssetAllocation
                snapshot={snapshot}
                snapshotToday={snapshotToday}
                isStaking={isStaking}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 4 }}>
              <AssetDistrubition asset={asset} isStaking={isStaking} />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 4 }}>
              <AssetByGroup asset={asset} isStaking={isStaking} />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 12 }}>
              <OverView
                snapshotToday={snapshotToday}
                snapshot={snapshot}
                selectData={selectData}
                setSelectData={setSelectData}
                asset={asset}
                title={title}
                setTitle={setTitle}
                dataPortfolio={portfolioSnapshotToday}
                portfolioSnapshot={portfolioSnapshot}
                isStaking={isStaking}
                setIsStaking={setIsStaking}
              />
            </Grid>
          </>
        )}
      </Grid>
      {(userName || !userConnected?.data?.is_manager) && (
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CumulativePnl
              title="Asset Net Worth"
              selectData={selectData!}
              type="networth"
              cryptoSelected={title}
              snapshot={snapshot}
              snapshotToday={snapshotToday}
              portfolioSnapshot={portfolioSnapshot}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CumulativePnl
              title="Cumulative PNL(%)"
              selectData={selectData!}
              type="percent"
              cryptoSelected={title}
              snapshot={snapshot}
              snapshotToday={snapshotToday}
              portfolioSnapshot={portfolioSnapshot}
            />
          </Grid>
        </Grid>
      )}
      {(userName || !userConnected?.data?.is_manager) && (
        <Grid container spacing={2} sx={{ marginTop: 2, marginBottom: 1 }}>
          <Grid size={{ xs: 12, sm: 12 }}>
            <AssetListing
              asset={asset}
              snapshot={snapshotToday}
              isLoadingSnapshot={snapshot.isLoading}
              isErrorSnapshot={snapshot.isError}
              errorSnapshot={snapshot.error}
              isAllData={!displayPortFolio.isActive}
              isAllowReportChoose={true}
              enableFilter_={!displayPortFolio.isActive}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 12 }}>
            <DeFi data={deFi} />
          </Grid>
        </Grid>
      )}

      {/* Bouton de retour en haut */}
      {showScrollButton && isVisible && (
        <ScrollToTopButton onClick={scrollToTop} />
      )}
    </>
  );
}
