import { allStateReduxType } from "../types/redux";

export const selectUser = (state: allStateReduxType) => state.user;
export const selectManager = (state: allStateReduxType) => state.manager;
export const selectLivePrice = (state: allStateReduxType) => state.livePrice;
export const selectAssetData = (state: allStateReduxType) => state.assetData;
export const selectEuroDollar = (state: allStateReduxType) => state.euroDollar;
export const selectTimeMachine = (state: allStateReduxType) =>
  state.timeMachine;
export const selectUserManaged = (state: allStateReduxType) =>
  state.userManaged;
export const selectAccountManager = (state: allStateReduxType) =>
  state.accountManager;
export const selectDisplayPortfolio = (state: allStateReduxType) =>
  state.displayPortfolio;
export const selectInvalidateDataSeeting = (state: allStateReduxType) =>
  state.invalidateDataSeeting;
export const selectAssetReport = (
  state: allStateReduxType,
  name: string
): string[] => {
  return state.assetReport?.[name] || [];
};
