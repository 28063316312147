import {
  FEES,
  ASSET,
  PRICE,
  NO_DATA,
  PNL_VALUE,
  NET_WORTH,
  ASSET_LIST,
  INVESTMENT,
  OWNED_ASSET,
  ALLOW_REPORT,
  PNL_PERCENTAGE,
  GENERATE_REPORT,
  FILTER_BY_BLOCKCHAIN,
  AVERAGE_PURCHASE_PRICE,
} from "../../constantes/app";
import { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Information from "./Information";
import Radio from "@mui/material/Radio";
import { Asset } from "../../types/asset";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import Loading from "../../shared/Loading";
import color from "../../constantes/color";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { useParams } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { Snapshot } from "../../types/snapshot";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { UseQueryResult } from "@tanstack/react-query";
import { getTextByLanguage } from "../../utils/function";
import TableContainer from "@mui/material/TableContainer";
import ChoiceReport from "../MonthlyReports/ChoiseReport";
import { useManageAsset, useManageSnapshot } from "./hooks";
import FilterListIcon from "@mui/icons-material/FilterList";
import FormControlLabel from "@mui/material/FormControlLabel";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import { selectEuroDollar, selectLivePrice } from "../../redux/selector";

export default function AssetListing({
  asset,
  snapshot,
  isLoadingSnapshot,
  isErrorSnapshot,
  errorSnapshot,
  enableFilter_ = true, // Default value set here
  colums = "all", // Default value set here
  isTranslation = false, // Default value set here
  language = "fr", // Default value set here
  isReportLastDate = false, // Default value set here
  isAllData = false,
  addTitle,
  isReport,
  isAllowReportChoose,
}: {
  asset: UseQueryResult<Asset[], Error>;
  snapshot: Snapshot[];
  isLoadingSnapshot: boolean;
  isErrorSnapshot: boolean;
  errorSnapshot: Error | null;
  enableFilter_?: boolean; // Optional parameter to say whether filter is avalaible, filter is avalaible if isAllData is true
  colums?: string[] | "all"; // Optional parameter to say what are they colums to enable on table
  isTranslation?: boolean; // Optional parameter to say to transalate word in this component
  language?: "fr" | "en"; // Optional parameter
  isReportLastDate?: boolean; // Optional parameter
  isAllData?: boolean; // Optional parameter to combine the data of asset and snapshot to get all asset possessed for user
  addTitle?: string; // Optional parameter to say if the title has to be added or No
  isReport?: boolean; // Optional parameter to say if this component avalaible on report or No
  isAllowReportChoose?: boolean; // Optional parameter to say if Generate report button is avalaible or No
}) {
  const [filterItem, setFilterItem] = useState<string>("All");
  const [filterData, setFilterData] = useState<string>("Essential");
  const [uniqueAssetPricesSnapshot, setUniqueAssetPricesSnapshot] = useState<{
    [key: string]: number;
  }>({});
  const [enableFilter, setEnableFilter] = useState<boolean>(false);
  const [openGenerateMonthlyReport, setOpenGenerateMonthlyReport] =
    useState(false);
  const userName = useParams<{ userName: string }>().userName;

  // Redux state
  const euroDollar = useSelector(selectEuroDollar);
  const livePrice = useSelector(selectLivePrice);

  const snapshotFormatted = useManageSnapshot(
    snapshot,
    asset,
    livePrice,
    uniqueAssetPricesSnapshot,
    setUniqueAssetPricesSnapshot,
    isReportLastDate,
    isAllData
  );

  const { allBlockchain, assetsFiltered } = useManageAsset(
    asset,
    livePrice,
    snapshotFormatted,
    filterItem,
    uniqueAssetPricesSnapshot
  );

  return (
    <>
      {openGenerateMonthlyReport && (
        <ChoiceReport
          open={openGenerateMonthlyReport}
          handleClose={() => setOpenGenerateMonthlyReport(false)}
        />
      )}
      <Card sx={{ borderRadius: 3 }}>
        <CardHeader
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          title={
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              {isAllowReportChoose && (
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  sx={{ flexDirection: "row", marginRight: 2 }}
                  defaultValue={filterData}
                  onChange={(event) => setFilterData(event.target.value)}
                >
                  <FormControlLabel
                    value="Essential"
                    control={<Radio />}
                    label="Essential"
                  />
                  <FormControlLabel
                    value="All"
                    control={<Radio />}
                    label="All"
                  />
                </RadioGroup>
              )}
              <Typography sx={{ flex: 1, textAlign: "center" }} fontSize={20}>
                {isTranslation
                  ? getTextByLanguage("ASSET_LIST", language)
                  : ASSET_LIST}{" "}
                {addTitle ? addTitle : ""}
              </Typography>
            </Box>
          }
          action={
            snapshot &&
            snapshot.length > 0 && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {userName && (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ marginRight: 2 }}
                    onClick={() => setOpenGenerateMonthlyReport(true)}
                  >
                    {GENERATE_REPORT}
                  </Button>
                )}
                {enableFilter && (
                  <>
                    <TextField
                      id="outlined-basic"
                      label="Blockchain"
                      variant="outlined"
                      fullWidth
                      size="small"
                      sx={{ marginRight: 1, width: 150 }}
                      select
                      onChange={(e) => setFilterItem(e.target.value)}
                    >
                      {allBlockchain.map((blockchain) => (
                        <MenuItem
                          key={blockchain.value}
                          value={blockchain.value}
                        >
                          {blockchain.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
                {enableFilter_ && (
                  <Tooltip title={FILTER_BY_BLOCKCHAIN}>
                    {enableFilter ? (
                      <IconButton
                        onClick={() => {
                          setEnableFilter(false);
                          setFilterItem("All");
                          setFilterData("Essential");
                        }}
                      >
                        <FilterListIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => setEnableFilter(true)}>
                        <FilterListOffIcon />
                      </IconButton>
                    )}
                  </Tooltip>
                )}
              </Box>
            )
          }
        />

        <Divider variant="middle" />
        <CardContent>
          {asset.isLoading || isLoadingSnapshot ? (
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <Loading color="inherit" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : asset.isError || isErrorSnapshot ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                color: color.red,
                fontWeight: "bold",
              }}
            >
              {asset.error?.message || errorSnapshot?.message}
            </Box>
          ) : snapshot.length > 0 && snapshotFormatted.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Loading color="inherit" />
            </Box>
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {isAllowReportChoose && userName && (
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {ALLOW_REPORT}
                      </TableCell>
                    )}
                    <TableCell align="right"></TableCell>
                    {(colums === "all" || colums.includes(ASSET)) && (
                      <TableCell align="left" sx={{ fontWeight: "bold" }}>
                        {isTranslation
                          ? getTextByLanguage("ASSET", language)
                          : ASSET}
                      </TableCell>
                    )}

                    {(colums === "all" || colums.includes(OWNED_ASSET)) && (
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {isTranslation
                          ? getTextByLanguage("OWNED_ASSETS", language)
                          : OWNED_ASSET}
                      </TableCell>
                    )}

                    {(colums === "all" || colums.includes(PRICE)) && (
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {PRICE} ({euroDollar.currency})
                      </TableCell>
                    )}

                    {(colums === "all" || colums.includes(NET_WORTH)) && (
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {isTranslation
                          ? getTextByLanguage("NET_WORTH", language)
                          : NET_WORTH}{" "}
                        ({euroDollar.currency})
                      </TableCell>
                    )}

                    {(colums === "all" || colums.includes(INVESTMENT)) && (
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {isTranslation
                          ? getTextByLanguage("INVESTMENT", language)
                          : INVESTMENT}{" "}
                        ({euroDollar.currency})
                      </TableCell>
                    )}

                    {(colums === "all" || colums.includes(PNL_VALUE)) && (
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {isTranslation
                          ? getTextByLanguage("PNL_VALUE", language)
                          : PNL_VALUE}{" "}
                        ({euroDollar.currency})
                      </TableCell>
                    )}

                    {(colums === "all" || colums.includes(PNL_PERCENTAGE)) && (
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {isTranslation
                          ? getTextByLanguage("PNL_PERCENT", language)
                          : PNL_PERCENTAGE}{" "}
                        (%)
                      </TableCell>
                    )}

                    {(colums === "all" || colums.includes(FEES)) && (
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {FEES} ({euroDollar.currency})
                      </TableCell>
                    )}

                    {(colums === "all" ||
                      colums.includes(AVERAGE_PURCHASE_PRICE)) && (
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {isTranslation
                          ? getTextByLanguage("AVG_PURCHASE_PRICE", language)
                          : AVERAGE_PURCHASE_PRICE}{" "}
                        ({euroDollar.currency})
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {snapshot && snapshot.length > 0 ? (
                    <>
                      {filterItem === "All" ? (
                        <Information
                          data={snapshotFormatted}
                          colums={colums}
                          isReportLastDate={isReportLastDate}
                          isAllowReportChoose={isAllowReportChoose}
                          isReport={isReport}
                          filterData={filterData}
                          language={language}
                          isTranslation={isTranslation}
                        />
                      ) : (
                        <Information
                          data={assetsFiltered}
                          colums={colums}
                          isAllowReportChoose={isAllowReportChoose}
                          isReportLastDate={isReportLastDate}
                          isReport={isReport}
                          filterData={filterData}
                          language={language}
                          isTranslation={isTranslation}
                        />
                      )}
                    </>
                  ) : (
                    <TableCell colSpan={10}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: "bold",
                          marginTop: 2,
                          fontSize: "1.5rem",
                        }}
                      >
                        {isTranslation
                          ? getTextByLanguage("NO_DATA", language)
                          : NO_DATA}
                      </Box>
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>
    </>
  );
}
