import List from "@mui/material/List";
import Card from "@mui/material/Card";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import EuroIcon from "@mui/icons-material/Euro";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import FolderIcon from "@mui/icons-material/Folder";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { selectEuroDollar } from "../../redux/selector";
import { calculateEuroDollar } from "../../utils/calcul";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

export default function DeltaDataVariation({
  pnl,
  netWorth,
  fiatBalance,
  stableCoin,
}: {
  pnl: number;
  netWorth: number;
  fiatBalance: number;
  stableCoin: number;
}) {
  const euroDollar = useSelector(selectEuroDollar);
  return (
    <Card>
      <CardHeader
        title="Variation data"
        sx={{
          textAlign: "center",
        }}
      />
      <Divider variant="middle" />
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem",
          borderRadius: "4px",
        }}
      >
        <List>
          <ListItem>
            <ListItemIcon>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText>
              Pnl :{" "}
              <strong
                style={{
                  fontWeight: "bold",
                  color: Number(pnl) >= 0 ? "green" : "red",
                }}
              >
                {isNaN(Number(pnl)) ? "0.00" : Number(pnl).toFixed(2)} %
              </strong>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              {euroDollar.currency === "$" ? <AttachMoneyIcon /> : <EuroIcon />}
            </ListItemIcon>
            <ListItemText>
              Net Worth :{" "}
              <strong
                style={{
                  fontWeight: "bold",
                  color: netWorth >= 0 ? "green" : "red",
                }}
              >
                {calculateEuroDollar(
                  netWorth,
                  euroDollar.currency,
                  euroDollar.valueEuroDollar
                ).toFixed(2)}
              </strong>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              {euroDollar.currency === "$" ? <AttachMoneyIcon /> : <EuroIcon />}
            </ListItemIcon>
            <ListItemText>
              Fiat Balance :{" "}
              <strong
                style={{
                  fontWeight: "bold",
                  color: fiatBalance >= 0 ? "green" : "red",
                }}
              >
                {calculateEuroDollar(
                  fiatBalance,
                  euroDollar.currency,
                  euroDollar.valueEuroDollar
                ).toFixed(2)}
              </strong>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              {euroDollar.currency === "$" ? <AttachMoneyIcon /> : <EuroIcon />}
            </ListItemIcon>
            <ListItemText>
              Stable Coin :{" "}
              <strong
                style={{
                  fontWeight: "bold",
                  color: stableCoin >= 0 ? "green" : "red",
                }}
              >
                {calculateEuroDollar(
                  stableCoin,
                  euroDollar.currency,
                  euroDollar.valueEuroDollar
                ).toFixed(2)}
              </strong>
            </ListItemText>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}
