import TableData from "./TableData";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Presentation from "./Presentation";
import Loading from "../../shared/Loading";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { UseQueryResult } from "@tanstack/react-query";

export default function DeFi({ data }: { data: UseQueryResult<any, Error> }) {
  const handleClick = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      {data.isLoading ? (
        <Loading color="inherit" />
      ) : data.isError ? (
        <Typography color={"red"} fontWeight={"bold"} fontSize={20}>
          {data.error?.message}
        </Typography>
      ) : data && data.data.length > 0 ? (
        data.data.map((item: any, index: number) => {
          return (
            item["details"]["defi_positions"].length > 0 && (
              <Paper
                key={index}
                elevation={3}
                sx={{
                  padding: 2,
                  marginBottom: 2,
                }}
              >
                <Typography variant={"h5"} align={"center"}>
                  DeFi
                </Typography>
                <Typography fontSize={10} align={"center"}>
                  {item["wallet"]}
                </Typography>
                <Divider
                  variant="middle"
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                />
                <Grid container spacing={2}>
                  {/* Présentation */}
                  <Grid size={{ xs: 12 }}>
                    <Grid container spacing={2}>
                      {item["details"]["defi_positions"]
                        .filter(
                          (item: any) =>
                            item["chains"][0]["investments"].length > 0
                        ) // Filtrer les éléments vides
                        .map((item: any, index: number) => {
                          const id = `table-${index}`;
                          return (
                            <Grid size={{ xs: 2 }} key={id}>
                              <Presentation
                                key={id}
                                data={item}
                                onClick={() => handleClick(id)}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>

                  {/* Table correspondante */}
                  <Grid size={{ xs: 12 }}>
                    <Grid container spacing={2}>
                      {item["details"]["defi_positions"]
                        .filter(
                          (item: any) =>
                            item["chains"][0]["investments"].length > 0
                        ) // Filtrer les éléments vides
                        .map((item: any, index: number) => {
                          const id = `table-${index}`;
                          return (
                            <Grid size={{ xs: 12, sm: 12 }} key={id} id={id}>
                              <TableData key={id} data={item} />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            )
          );
        })
      ) : null}
    </>
  );
}
