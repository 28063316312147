import Box from "@mui/material/Box";
import Highcharts from "highcharts";
import Card from "@mui/material/Card";
import { useSelector } from "react-redux";
import Loading from "../../shared/Loading";
import color from "../../constantes/color";
import Divider from "@mui/material/Divider";
import { Snapshot } from "../../types/snapshot";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { useEffect, useMemo, useState } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import HighchartsReact from "highcharts-react-official";
import { getTextByLanguage } from "../../utils/function";
import Accessibility from "highcharts/modules/accessibility";
import { ASSET_ALLOCATION, NO_DATA } from "../../constantes/app";
import { selectDisplayPortfolio, selectLivePrice } from "../../redux/selector";

// Activer le module d'accessibilité
Accessibility(Highcharts);

type formatData = {
  name: string;
  y: number;
};

export default function AssetAllocation({
  snapshot,
  snapshotToday,
  isTranslation = false,
  isReport = false,
  language = "fr",
  isStaking
}: {
  snapshot: UseQueryResult<Snapshot[], Error>;
  snapshotToday?: Snapshot[];
  isTranslation?: boolean;
  isReport?: boolean; // Pour activer le rapport sur les allocations
  language?: "fr" | "en";
  isStaking? : boolean
}) {
  const [finalData, setFinalData] = useState<formatData[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const livePrice = useSelector(selectLivePrice);

  const displayPortFolio = useSelector(selectDisplayPortfolio);

  useEffect(() => {
    if (snapshotToday && snapshotToday.length) {
      const groupedData: { [key: string]: number } = {};

      // Regrouper les données par blockchain et additionner les valeurs
      snapshotToday.forEach((el) => {
        const asset = el.asset;
        groupedData[asset] =
          (groupedData[asset] || 0) +
          (isReport || displayPortFolio.isActive
            ? parseFloat(el.asset_net_worth)
            : livePrice && livePrice[asset]
            ? livePrice[asset] * parseFloat(el.owned_assets)
            : 0);
      });

      // Formater et trier les données par ordre décroissant
      const formattedData = Object.entries(groupedData)
        .map(([name, count]) => ({ name, y: count }))
        .sort((a, b) => b.y - a.y);

      // Regrouper les petites valeurs dans "OTHERS"
      const total = formattedData.reduce((sum, item) => sum + item.y, 0);

      // Mettre à jour les états
      setFinalData(formattedData);
      setTotalData(total);
    }
  }, [snapshotToday, displayPortFolio.isActive, isReport, livePrice]);

  // Mémoïser les options du graphique pour éviter les re-rendus inutiles
  const options = useMemo(
    () => ({
      credits: {
        enabled: false,
      },
      chart: {
        type: "pie",
        height: 270,
      },
      title: {
        text: "",
      },
      tooltip: {
        // @ts-ignore
        formatter: function () {
          return (
            "<b>" +
            // @ts-ignore
            this.point.name +
            "</b><br/> ~ " +
            // @ts-ignore
            Highcharts.numberFormat(this.point.percentage, 1) +
            " %"
          );
        },
      },
      plotOptions: {
        pie: {
          animation: false, // Désactiver l'animation pour de meilleures performances
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
          colors: ["#01BAF2", "#71BF45", "#FAA74B", "#B37CD2"],
          showInLegend: true,
        },
      },
      legend: {
        align: "right", // Positionner la légende à droite
        verticalAlign: "middle", // Centrer verticalement
        layout: "vertical", // Disposer les éléments verticalement
        itemMarginBottom: 10, // Ajouter de l'espace entre les éléments
        symbolRadius: 0, // Forme carrée
        symbolHeight: 8,
        symbolWidth: 8, // @ts-ignore
        labelFormatter: function () {
          return (
            // @ts-ignore
            this.name +
            " : " +
            // @ts-ignore
            Highcharts.numberFormat((this.y / totalData) * 100, 1) +
            " %"
          );
        },
      },
      series: [
        {
          innerSize: "75%",
          data: finalData,
        },
      ],
    }),
    [finalData, totalData]
  );

  return (
    <Card
      sx={{
        borderRadius: "1rem",
      }}
    >
      <CardHeader
        sx={{
          textAlign: "center",
        }}
        title={
          isTranslation
            ? getTextByLanguage("ASSET_ALLOCATION", language)
            : ASSET_ALLOCATION
        }
      />
      <Divider variant="middle" />
      <CardContent sx={{ mt: -2, height: isStaking ? "29.75rem" : "22.75rem" }}>
        {snapshot?.isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Loading size={15} color="inherit" />
          </Box>
        ) : snapshot?.isError ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: color.red,
              fontWeight: "bold",
              fontSize: "1.2rem",
              textAlign: "center",
              height: "100%",
            }}
          >
            {snapshot.error.message}
          </Box>
        ) : (
          <>
            {snapshotToday && snapshotToday.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%", // S'assure que le Box prend toute la largeur
                  height: "100%", // S'assure que le Box prend toute la hauteur
                }}
              >
                <HighchartsReact highcharts={Highcharts} options={options} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                {NO_DATA}
              </Box>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
