import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import { selectEuroDollar } from "../../redux/selector";
import { calculateEuroDollar } from "../../utils/calcul";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

export default function Presentation({
  data,
  onClick,
}: {
  data: any;
  onClick: () => void;
}) {
  const euroDollar = useSelector(selectEuroDollar);

  return (
    <Card
      sx={{
        maxWidth: 345,
        cursor: "pointer",
        transition: "0.3s",
        "&:hover": { boxShadow: 6 },
      }}
      onClick={onClick}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ width: 30, height: 30 }} src={data["platform_logo"]} />
        }
        action={
          <IconButton
            component="a"
            href={data["platform_url"]}
            target="_blank"
            rel="noopener noreferrer"
            size="small"
            onClick={(e) => e.stopPropagation()}
          >
            <InsertLinkIcon />
          </IconButton>
        }
        title={data["platform_name"]}
        subheader={
          euroDollar.currency === "$"
            ? `$${parseFloat(data["platform_total_value"]).toFixed(2)}`
            : `${calculateEuroDollar(
                parseFloat(data["platform_total_value"]),
                euroDollar.currency,
                euroDollar.valueEuroDollar
              ).toFixed(2)}€`
        }
      />
    </Card>
  );
}
