import {
  calculateEuroDollar,
  calculateAssetNetWorth,
} from "../../utils/calcul";
import {
  selectTimeMachine,
  selectDisplayPortfolio,
} from "../../redux/selector";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AggregatedDataSnapshot } from "../../types";
import { UseQueryResult } from "@tanstack/react-query";
import { PortofolioSnapshot, Snapshot } from "../../types/snapshot"; // Correct spelling here

type SerieData = {
  name: string;
  data: {
    x: number;
    y: number;
  }[];
};

export const useManageData = (
  snapshot: UseQueryResult<Snapshot[], Error>,
  portfolioSnapshot: UseQueryResult<PortofolioSnapshot[], Error>, // Correct spelling here
  euroDollar: { currency: string; valueEuroDollar: number },
  cryptoSelected: string,
  type: "percent" | "simple" | "networth",
  selectData: AggregatedDataSnapshot | undefined
) => {
  const [finalDataCrypto, setFinalDataCrypto] = useState<SerieData>({
    name: "",
    data: [],
  });
  const [finalDataOverview, setFinalDataOverview] = useState<SerieData>({
    name: "",
    data: [],
  });

  const [snapshotData, setSnapshotData] = useState<Snapshot[]>([]);
  const [portfolioSnapshotData, setPortfolioSnapshotData] = useState<
    PortofolioSnapshot[]
  >([]); // Correct spelling here

  const displayPortfolio = useSelector(selectDisplayPortfolio);
  const timeMachine = useSelector(selectTimeMachine);

  useEffect(() => {
    if (
      snapshot.data &&
      snapshot.data.length > 0 &&
      portfolioSnapshot.data &&
      portfolioSnapshot.data.length > 0
    ) {
      const dateDispayPortfolio = new Date(displayPortfolio.date!);
      const timeMachineStartDate = new Date(timeMachine.startDate!);
      const timeMachineEndDate = new Date(timeMachine.endDate!);

      setSnapshotData(
        displayPortfolio.isActive
          ? snapshot.data.filter(
              (el: Snapshot) => new Date(el.today) <= dateDispayPortfolio
            )
          : timeMachine.isActive
          ? snapshot.data.filter(
              (el: Snapshot) =>
                timeMachineStartDate <= new Date(el.today) &&
                new Date(el.today) <= timeMachineEndDate
            )
          : snapshot.data
      );
      setPortfolioSnapshotData(
        displayPortfolio.isActive
          ? portfolioSnapshot.data.filter(
              (
                el: PortofolioSnapshot // Correct spelling here
              ) => new Date(el.today) <= dateDispayPortfolio
            )
          : timeMachine.isActive
          ? portfolioSnapshot.data.filter(
              (
                el: PortofolioSnapshot // Correct spelling here
              ) =>
                timeMachineStartDate <= new Date(el.today) &&
                new Date(el.today) <= timeMachineEndDate
            )
          : portfolioSnapshot.data
      );
    }
  }, [
    snapshot.data,
    portfolioSnapshot.data,
    displayPortfolio.date,
    displayPortfolio.isActive,
    timeMachine.startDate,
    timeMachine.endDate,
    timeMachine.isActive,
  ]);

  useEffect(() => {
    if (snapshotData && portfolioSnapshotData) {
      if (cryptoSelected === "overview") {
        const groupedData = portfolioSnapshotData.reduce(
          (acc: SerieData, el: PortofolioSnapshot) => {
            // Correct spelling here
            const found = acc.data.find(
              (serie: { x: number; y: number }) =>
                serie.x === new Date(el.today).getTime()
            );
            if (found) {
              found.y +=
                type === "percent"
                  ? parseFloat(
                      parseFloat(el.unrealized_cum_pnl_rate).toFixed(2)
                    ) * 100
                  : type === "simple"
                  ? calculateEuroDollar(
                      parseFloat(parseFloat(el.unrealized_cum_pnl).toFixed(2)),
                      euroDollar.currency,
                      euroDollar.valueEuroDollar
                    )
                  : calculateEuroDollar(
                      parseFloat(parseFloat(el.crypto_net_worth).toFixed(2)),
                      euroDollar.currency,
                      euroDollar.valueEuroDollar
                    );
            } else {
              acc.data.push({
                x: new Date(el.today).getTime(),
                y:
                  type === "percent"
                    ? parseFloat(
                        parseFloat(el.unrealized_cum_pnl_rate).toFixed(2)
                      ) * 100
                    : type === "simple"
                    ? calculateEuroDollar(
                        parseFloat(
                          parseFloat(el.unrealized_cum_pnl).toFixed(2)
                        ),
                        euroDollar.currency,
                        euroDollar.valueEuroDollar
                      )
                    : calculateEuroDollar(
                        parseFloat(parseFloat(el.crypto_net_worth).toFixed(2)),
                        euroDollar.currency,
                        euroDollar.valueEuroDollar
                      ),
              });
            }
            return acc;
          },
          {
            name:
              type === "percent"
                ? "Cumulative PnL(%) Global"
                : type === "simple"
                ? "Cumulative PnL($) Global"
                : "Net Worth($)",
            data: [],
          }
        );
        if (!displayPortfolio.isActive && !timeMachine.isActive) {
          groupedData.data.push({
            x: new Date().getTime(),
            y:
              type === "percent"
                ? parseFloat(
                    (
                      (parseFloat(selectData?.portfolio_unrealized_cum_pnl!) /
                        parseFloat(selectData?.portfolio_investment!)) *
                      100
                    ).toFixed(2)
                  )
                : type === "simple"
                ? calculateEuroDollar(
                    parseFloat(
                      parseFloat(
                        selectData?.portfolio_unrealized_cum_pnl!
                      ).toFixed(2)
                    ),
                    euroDollar.currency,
                    euroDollar.valueEuroDollar
                  )
                : calculateEuroDollar(
                    parseFloat(selectData?.crypto_net_worth!),
                    euroDollar.currency,
                    euroDollar.valueEuroDollar
                  ),
          });
        }
        groupedData.data.sort((a, b) => a.x - b.x);
        setFinalDataOverview(groupedData);
      } else {
        const filteredData = snapshotData.filter(
          (el: Snapshot) => el.asset === cryptoSelected
        );

        const groupedFilteredData = filteredData.reduce(
          (acc: SerieData, el: Snapshot) => {
            const found = acc.data.find(
              (serie: { x: number; y: number }) =>
                serie.x === new Date(el.today).getTime()
            );
            if (found) {
              found.y +=
                type === "percent"
                  ? parseFloat(
                      (parseFloat(el.unrealized_cum_pnl_rate) * 100).toFixed(2)
                    )
                  : type === "simple"
                  ? calculateEuroDollar(
                      parseFloat(parseFloat(el.unrealized_cum_pnl).toFixed(2)),
                      euroDollar.currency,
                      euroDollar.valueEuroDollar
                    )
                  : calculateEuroDollar(
                      parseFloat(
                        calculateAssetNetWorth(
                          parseFloat(el.asset_price),
                          parseFloat(el.owned_assets)
                        ).toFixed(2)
                      ),
                      euroDollar.currency,
                      euroDollar.valueEuroDollar
                    );
            } else {
              acc.data.push({
                x: new Date(el.today).getTime(),
                y:
                  type === "percent"
                    ? parseFloat(
                        (parseFloat(el.unrealized_cum_pnl_rate) * 100).toFixed(
                          2
                        )
                      )
                    : type === "simple"
                    ? calculateEuroDollar(
                        parseFloat(
                          parseFloat(el.unrealized_cum_pnl).toFixed(2)
                        ),
                        euroDollar.currency,
                        euroDollar.valueEuroDollar
                      )
                    : calculateEuroDollar(
                        parseFloat(
                          calculateAssetNetWorth(
                            parseFloat(el.asset_price),
                            parseFloat(el.owned_assets)
                          ).toFixed(2)
                        ),
                        euroDollar.currency,
                        euroDollar.valueEuroDollar
                      ),
              });
            }
            return acc;
          },
          {
            name: cryptoSelected,
            data: [],
          }
        );

        if (!displayPortfolio.isActive && !timeMachine.isActive) {
          groupedFilteredData.data.push({
            x: new Date().getTime(),
            y:
              type === "percent"
                ? parseFloat(
                    parseFloat(
                      selectData?.portfolio_unrealized_cum_pnl_rate!
                    ).toFixed(2)
                  )
                : type === "simple"
                ? calculateEuroDollar(
                    parseFloat(
                      parseFloat(
                        selectData?.portfolio_unrealized_cum_pnl!
                      ).toFixed(2)
                    ),
                    euroDollar.currency,
                    euroDollar.valueEuroDollar
                  )
                : calculateEuroDollar(
                    parseFloat(selectData?.crypto_net_worth!),
                    euroDollar.currency,
                    euroDollar.valueEuroDollar
                  ),
          });
        }

        groupedFilteredData.data.sort((a, b) => a.x - b.x);

        setFinalDataCrypto(groupedFilteredData);
      }
    } else {
      setFinalDataCrypto({ name: "", data: [] });
    }
  }, [
    snapshotData,
    cryptoSelected,
    type,
    portfolioSnapshotData,
    euroDollar,
    selectData,
    displayPortfolio.isActive,
    timeMachine.isActive,
  ]);

  return { finalDataCrypto, finalDataOverview };
};
